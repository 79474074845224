import { betMessages } from 'constants/toastMessages';
import { setConfirmationSlotAction } from 'core/widgets/BetConfirmation/actions';
import { tableBetLimitSelector } from 'core/widgets/TableSettings';
import { IBetLimit } from 'core/widgets/TableSettings/types';
import { addToastAction } from 'core/widgets/Toast/actions';
import { balanceSelector } from 'core/widgets/User';
import { put, select } from 'redux-saga/effects';
import { ToastTypes } from 'types/toast';
import { ActionType } from 'typesafe-actions';
import { SLOTS } from 'types';
import { isUserInStageJPSelector } from 'core/widgets/Jackpot/selectors';
import { getIntegerHandler, preciseAddSubtract, roundToFixed } from 'utils/roundToFix';
import { placeBetAction, placeBetPendingAction, placeBetSuccessAction } from '../actions';
import { confirmedBetSelector, placedBetSelector } from '../selectors';
import { BetType } from '../types';
import { getBetSum, getSummaryBet, getTotalSlotBetSum } from '../utils';
import { EMPTY_BET } from '../data';

export function* placeBetSaga({ payload: newBet }: ActionType<typeof placeBetAction>) {
  const balance: number | null = yield select(balanceSelector);
  const tableLimits: IBetLimit | undefined = yield select(tableBetLimitSelector);
  const placedBetResult: BetType = yield select(placedBetSelector);
  const confirmedBets: BetType[] = yield select(confirmedBetSelector);
  const totalConfirmedBets: BetType = getSummaryBet(confirmedBets);
  const betSum = getBetSum([totalConfirmedBets]);
  const isSameBetPosition = placedBetResult[newBet.slot] > 0;
  // @ts-ignore
  const isUserInStageJP = yield select(isUserInStageJPSelector);

  if (!balance || !tableLimits) {
    return;
  }

  const placedBet = isUserInStageJP
    ? betSum > 0 || isSameBetPosition
      ? placedBetResult
      : EMPTY_BET
    : placedBetResult;

  const placedBetSumBeforeNewBet: number = getBetSum([placedBetResult]);
  const bet = newBet.value > balance ? newBet.value : balance;

  if (tableLimits.min > bet && placedBet[newBet.slot] <= tableLimits.min) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));

    return;
  }

  const newPlacedBetSum: number = preciseAddSubtract(
    getBetSum([placedBetResult]),
    newBet.value,
    'add',
  );

  if (placedBetSumBeforeNewBet >= tableLimits.max && placedBet[SLOTS.tie] === tableLimits.max) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
    return;
  }
  if (
    (newBet.slot === SLOTS.player && placedBetResult.banker > 0) ||
    (newBet.slot === SLOTS.banker && placedBetResult.player > 0)
  ) {
    if (newBet.slot === SLOTS.banker && placedBetResult.player > 0) {
      const deltaBetsSum = placedBetResult.player;
      const currentBetsSum = preciseAddSubtract(placedBetSumBeforeNewBet, deltaBetsSum, 'subtract');
      const currentBetsSumBalance = currentBetsSum < balance ? currentBetsSum : balance;
      const diffBalance = tableLimits.max > balance ? balance : tableLimits.max;
      const availableBet =
        newBet.value < preciseAddSubtract(diffBalance, currentBetsSumBalance, 'subtract')
          ? newBet.value
          : preciseAddSubtract(diffBalance, currentBetsSumBalance, 'subtract');
      const resultBet: BetType = {
        ...placedBet,
        [SLOTS.player]: 0,
        [newBet.slot]: preciseAddSubtract(availableBet, placedBet[newBet.slot], 'add'),
      };
      if (availableBet < newBet.value && getBetSum([resultBet]) === tableLimits.max) {
        yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
      }
      yield put(placeBetSuccessAction(resultBet));
      return;
    }

    if (newBet.slot === SLOTS.player && placedBetResult.banker > 0) {
      const deltaBetsSum = placedBetResult.banker;
      const currentBetsSum = preciseAddSubtract(placedBetSumBeforeNewBet, deltaBetsSum, 'subtract');
      const currentBetsSumBalance = currentBetsSum < balance ? currentBetsSum : balance;
      const diffBalance = tableLimits.max > balance ? balance : tableLimits.max;
      const availableBet =
        newBet.value < preciseAddSubtract(diffBalance, currentBetsSumBalance, 'subtract')
          ? newBet.value
          : preciseAddSubtract(diffBalance, currentBetsSumBalance, 'subtract');

      const resultBet: BetType = {
        ...placedBet,
        [SLOTS.banker]: 0,
        [newBet.slot]: preciseAddSubtract(availableBet, placedBet[newBet.slot], 'add'),
      };
      if (availableBet < newBet.value && getBetSum([resultBet]) === tableLimits.max) {
        yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
      }
      yield put(placeBetSuccessAction(resultBet));
      return;
    }
    return;
  }

  if (placedBetSumBeforeNewBet === tableLimits.max) {
    if (isUserInStageJP && !isSameBetPosition) {
      const diffBalance = tableLimits.max > balance ? balance : tableLimits.max;
      const currentBet = newBet.value > diffBalance ? diffBalance : newBet.value;
      const resultBet: BetType = {
        ...placedBet,
        [newBet.slot]: preciseAddSubtract(currentBet, placedBet[newBet.slot], 'add'),
      };
      yield put(placeBetSuccessAction(resultBet));
      return;
    }

    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
    return;
  }

  if (newPlacedBetSum > tableLimits.max) {
    const newAvaliableBet = preciseAddSubtract(balance, placedBetSumBeforeNewBet, 'subtract');

    if (isUserInStageJP && !isSameBetPosition) {
      const diffBalance = tableLimits.max > balance ? balance : tableLimits.max;
      const currentBet = newBet.value > diffBalance ? diffBalance : newBet.value;
      const resultBet: BetType = {
        ...placedBet,
        [newBet.slot]: preciseAddSubtract(currentBet, placedBet[newBet.slot], 'add'),
      };
      yield put(placeBetSuccessAction(resultBet));
      return;
    }

    if (!newAvaliableBet) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
      return;
    }

    const diffBalance = tableLimits.max > balance ? balance : tableLimits.max;
    const availableBalance = preciseAddSubtract(diffBalance, placedBetSumBeforeNewBet, 'subtract');
    const resultBet: BetType = {
      ...placedBet,
      [newBet.slot]: preciseAddSubtract(availableBalance, placedBet[newBet.slot], 'add'),
    };
    if (tableLimits.max <= balance) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
    }
    yield put(placeBetSuccessAction(resultBet));
    return;
  }

  const newAvaliableBet = preciseAddSubtract(balance, placedBetSumBeforeNewBet, 'subtract');

  if (newBet.value > newAvaliableBet) {
    if (isUserInStageJP && !isSameBetPosition) {
      const diffBalance = tableLimits.max > balance ? balance : tableLimits.max;
      const currentBet = newBet.value > diffBalance ? diffBalance : newBet.value;
      const resultBet: BetType = {
        ...placedBet,
        [newBet.slot]: preciseAddSubtract(currentBet, placedBet[newBet.slot], 'add'),
      };
      yield put(placeBetSuccessAction(resultBet));
      return;
    }
    if (!newAvaliableBet && balance < tableLimits.max) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
      return;
    }

    if (newAvaliableBet < tableLimits.min && placedBet[newBet.slot] === 0) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
      return;
    }

    const resultBet: BetType = {
      ...placedBet,
      [newBet.slot]: preciseAddSubtract(newAvaliableBet, placedBet[newBet.slot], 'add'),
    };

    yield put(placeBetSuccessAction(resultBet));
    return;
  }
  if (
    newBet.value > balance &&
    preciseAddSubtract(placedBetSumBeforeNewBet, balance, 'subtract') === 0
  ) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
    return;
  }

  const resultBet: BetType = {
    ...placedBet,
    [newBet.slot]: preciseAddSubtract(newBet.value, placedBet[newBet.slot], 'add'),
  };

  yield put(placeBetSuccessAction(resultBet));
}
